
<script setup lang="ts">

/* global defineProps, defineEmits */
/* eslint-disable */
import { ref/*, defineEmits, defineProps */ } from "vue";

const props = defineProps(['list', 'address', 'walletObj', 'hasConnected']);

const emit = defineEmits(["collectionRefresh"]);

let claimList: any = [];
let startClaimProcess: any = ref(false);
let finishedClaimProcess: any = ref('');
let setLoadingScreen: any = ref(false);
let setPostLoadingScreen: any = ref(false);

const shippingName = ref("");
const shippingAddress = ref("");
const addressLine2 = ref("");
const email = ref("");
const phoneNumber = ref("");
const verifyEmail = ref("");
const shippingCity = ref("");
const shippingState = ref("");
const shippingCountry = ref("");
const shippingZip = ref("");

const claimNFTs = async (address: any, ethereum: any, { tokenIds, shippingName, shippingAddress, email, shippingCity, shippingState, shippingZip, shippingCountry, phoneNumber }: any) => {
  setLoadingScreen.value = true
  startClaimProcess.value = false;
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  const wallet = address
  const messageSignature = await window.ethereum._object.web3._provider.request({ method: "personal_sign", params: ["verify wallet: " + wallet + "\r\nclaiming token(s): " + tokenIds, wallet] })
  setLoadingScreen.value = false
  setPostLoadingScreen.value = true
  return await fetch('https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-tartot-cards-claim-api', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      wallet: wallet,
      messageSignature,
      tokenIds,
      shippingAddress,
      shippingName,
      email,
      shippingCity,
      shippingState,
      shippingZip,
      shippingCountry,
      phoneNumber
    }),
  });
};

const selectNFT = (index: any, toggle: any) => {
  console.log("setting: ", index, " to ", toggle)
  props.list[index].selectToClaim = toggle;
  if (toggle) {
    claimList.push(props.list[index]);
  } else {
    claimList.forEach((element: any, pos: any) => {
      if (element.tokenId === props.list[index].tokenId) {
        claimList.splice(pos, 1);
      }
    });
  }
}

const clickClaim = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  startClaimProcess.value = true;
}

const cancelClaimModal = () => {
  startClaimProcess.value = false;
}

const closeMsgModal = () => {
  finishedClaimProcess.value = '';
}

const claimRewards = (walletObj: any) => {
  // console.log("claimrewards() - walletObj: ", walletObj)
  // console.log("props: ", props)
  var tokenIds: any = claimList.map((nft: any) => { return nft.tokenId.toString() })
  tokenIds = tokenIds.join(",");
  // console.log("tokenIds = ", tokenIds)
  if (shippingName.value && shippingAddress.value && email.value && verifyEmail.value && phoneNumber.value) {
    if (email.value === verifyEmail.value) {
      claimNFTs(props.address, window.web3, {
        tokenIds: tokenIds,
        shippingName: shippingName.value,
        shippingAddress: shippingAddress.value + (addressLine2.value ? " - " + addressLine2.value : ""),
        email: email.value,
        shippingCity: shippingCity.value,
        shippingState: shippingState.value,
        shippingZip: shippingZip.value,
        shippingCountry: shippingCountry.value,
        phoneNumber: phoneNumber.value
      }).then((claimed) => {
        setPostLoadingScreen.value = false
        setLoadingScreen.value = false
        if (claimed.status === 200) {
          // console.log(claimed, props.list)
          emit("collectionRefresh", true);
          // props.list[index].claimed = true;
          cancelClaimModal();
          finishedClaimProcess.value = 'success';
          for (var i = 0; i < props.list.length; i++) {
            selectNFT(i, false)
          }
        } else {
          cancelClaimModal();
          finishedClaimProcess.value = 'error';
        }
      }, () => {
        setPostLoadingScreen.value = false
        setLoadingScreen.value = false
        cancelClaimModal();
        finishedClaimProcess.value = 'error';
        for (var i = 0; i < props.list.length; i++) {
          selectNFT(i, false)
        }
      });
    }
  }
} 
</script>

<template>

  <div class="collection-viewer">
    <div class="container">

  

      <div class="column column-two">
        <img class="logo" src="../../assets/imgs/justinbanner.jpg" alt="logo" />
        <div>
          The claim period for Tarot Scrolls has ended.
        </div>
        <!-- <h3 class="claim-heading">Select your NFT(s) to claim rewards</h3> -->
      </div>

    

    </div>

    <!-- <div class="claim-btn-container">
      <button v-if="claimList.length" class="claim-rewards-btn" @click="clickClaim()">Claim Rewards</button>
    </div> -->

    <div class="main-container-JA-Scrolls">
      <div v-if="!address" class="connect-msg">Connect your wallet to see the NFTs eligable for rewards.</div>
      <div v-if="address && !hasConnected" class="connect-msg">Loading...</div>
      
      <div v-if="address && hasConnected && !list.length" class="connect-msg">Sorry your wallet doesn't hold any qualifying NFTs for this rewards campaign.</div>
      <ul v-if="address" class="collection">
        <li v-for="item, index in list" class="collection-item"
          v-bind:class="{ selected: item.selectToClaim, claimed: item.claimed }">
          <h1>{{ item.title }}</h1>
          <div class="collection-item-content">
            <div class="collection-item-thumbnail">
              <img v-bind:src="item.image" v-bind:alt="item.title" />
            </div>
            <div v-if="item.claimed" class="collection-item-claimed">
              <h5>CLAIMED</h5>
            </div>
            <div v-if="!item.claimed">
              <button v-if="!item.selectToClaim" class="select-nft-item" @click="selectNFT(index, true)">
                Select
                <!-- {{ parseInt(item.tokenId) }} -->
              </button>
              <button v-if="item.selectToClaim" class="select-nft-item" @click="selectNFT(index, false)">
                Unselect
                 <!-- {{ parseInt(item.tokenId)}} -->
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="setLoadingScreen" class="claim-modal z-index-99">
      <h3 style="text-align: center;">Sign a message in your wallet to complete the process.</h3>
    </div>

    <div v-if="setPostLoadingScreen" class="claim-modal z-index-99">
      <h3 style="text-align: center;">Please wait a moment...</h3>
    </div>
    <!-- claimList.length && startClaimProcess -->
    <div v-if="claimList.length && startClaimProcess" class="claim-modal z-index-98">
      <h1 class="claim-modal-header">Almost there!</h1>
      <div class="claim-modal-content">
        <div class="claim-list">
          You will be claiming rewards for the following:
          <div><span v-for="item in claimList">{{ item.title }}</span></div>
        </div>
        <div class="claim-address-form">
          <span>Please enter your mailing address</span>
          <form name="item-form" @submit.prevent="claimRewards(props.walletObj)">
            <input v-model="shippingName" placeholder="Your name" />
            <input v-model="shippingAddress" placeholder="Street Address" />
            <input v-model="addressLine2" placeholder="Optional Address Line" />
            <input v-model="shippingCity" placeholder="City" />
            <input v-model="shippingState" placeholder="State" />
            <input v-model="shippingZip" placeholder="ZipCode" />
            <input v-model="shippingCountry" placeholder="Country" />
            <input v-model="email" placeholder="Email" />
            <input v-model="verifyEmail" placeholder="Verify email" />
            <input v-model="phoneNumber" placeholder="Phone Number" />

            <button type="submit">Claim Rewards!</button>
          </form>
        </div>
      </div>
      <div class="claim-modal-btns">
        <button class="cancel-btn" @click="cancelClaimModal()">+</button>
      </div>
    </div>

    <div v-if="finishedClaimProcess === 'success'" class="claim-modal close-modal">
      <h1 class="claim-modal-header">Congratulations!</h1>
      <div class="claim-modal-content">
        <div class="claim-list">
          You have successfully claimed the reward for your selected NFT(s)! Please check your email for claim
          confirmation.
        </div>
      </div>
      <div class="claim-modal-btns">
        <button class="cancel-btn" @click="closeMsgModal()">+</button>
      </div>
    </div>

    <div v-if="finishedClaimProcess === 'error'" class="claim-modal close-modal">
      <h1 class="claim-modal-header">Oops!</h1>
      <div class="claim-modal-content">
        <div class="claim-list">
          The claiming process was not completed for your selected NFT(s). If you are having issues please contact info@metavate.io and
          include your wallet address and NFT token ID(s) so we can work to resolve this!
        </div>
      </div>
      <div class="claim-modal-btns">
        <button class="cancel-btn" @click="closeMsgModal()">+</button>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>