import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import IndexView from "./views/IndexView.vue"
import JustinView from './views/JustinView.vue'
// import Zevi from './views/Zevi.vue'
import ArtpocalypseView from './views/ArtpocalypseView.vue'
import RaffleView from './views/RaffleView.vue'
import RaffleView1155 from './views/RaffleView1155.vue'
import RaffleHome from './views/RaffleHome.vue'
import RaffleRegister from './views/RaffleRegister.vue'
import CreateRaffle from './views/CreateRaffle.vue'
import CreateRaffle1155 from './views/CreateRaffle1155.vue'
import AboutPage from './views/AboutPage.vue'


// For info on using Vue Router with the Composition API, see https://next.router.vuejs.org/guide/advanced/composition-api.html

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: IndexView,
    meta: {
      title: 'NFT Redeem',
      metaTags: [
        {
          name: 'description',
          content: 'NFT Redeem - your portal for NFT rewards'
        },
        {
          property: 'og:description',
          content: 'NFT Redeem - your portal for NFT rewards'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
  },
  {
    path: '/justinaversano',
    name: 'Justin Aversano',
    component: JustinView,
    meta: {
      title: 'Justin Aversano',
      metaTags: [
        {
          name: 'description',
          content: 'Claim your Justin Aversano Smoke and Mirrors Physical Tarot Scroll'
        },
        {
          property: 'og:description',
          content: 'Claim your Justin Aversano Smoke and Mirrors Physical Tarot Scroll'
        }
      ]
    }
  },
  {
    path: '/raffle',
    name: 'Raffle',
    component: RaffleView,
    meta: {
      title: 'NFT Redeem Raffle',
      metaTags: [
        {
          name: 'description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        },
        {
          property: 'og:description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        }
      ]
    }
  },{
    path: '/raffle-1155',
    name: '1155Raffle',
    component: RaffleView1155,
    meta: {
      title: 'NFT Redeem Raffle',
      metaTags: [
        {
          name: 'description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        },
        {
          property: 'og:description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        }
      ]
    }
  },
  {
    path: '/raffle-home',
    name: 'RaffleHome',
    component: RaffleHome,
    meta: {
      title: 'NFT Redeem Raffle',
      metaTags: [
        {
          name: 'description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        },
        {
          property: 'og:description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        }
      ]
    }
  },
  {
    path: '/register-raffle-user',
    name: 'RegisterRaffleUser',
    component: RaffleRegister,
    meta: {
      title: 'NFT Redeem Raffle',
      metaTags: [
        {
          name: 'description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        },
        {
          property: 'og:description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        }
      ]
    }
  },
  {
    path: '/create-raffle',
    name: 'CreateRaffle',
    component: CreateRaffle,
    meta: {
      title: 'NFT Redeem Raffle',
      metaTags: [
        {
          name: 'description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        },
        {
          property: 'og:description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        }
      ]
    }
  },
  {
    path: '/create-raffle-1155',
    name: 'CreateRaffle1155',
    component: CreateRaffle1155,
    meta: {
      title: 'NFT Redeem Raffle',
      metaTags: [
        {
          name: 'description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        },
        {
          property: 'og:description',
          content: 'Reward your holders and win prizes using the NFT Redeem Raffle'
        }
      ]
    }
  },
  {
    path: '/artpocalypse',
    name: 'Artpocalypse',
    component: ArtpocalypseView,
    meta: {
      title: 'Artpocalypse',
      metaTags: [
        {
          name: 'description',
          content: 'Bring your fineartmfers and survive the Artpocalypse!'
        },
        {
          property: 'og:description',
          content: 'Bring your fineartmfers and survive the Artpocalypse!'
        }
      ]
    }
  },
  // {
  //   path: '/456cc',
  //   name: '456 Collectors Club',
  //   component: Zevi,
  // },
  // Fallback route for handling 404s
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('./views/Error404View.vue'),
    // component: () => import('./views/Error404View.vue'),
  },
]

const router = createRouter({
  // If app is not hosted at the domain root, make sure to pass the `base` input here: https://next.router.vuejs.org/api/#parameters
  history: createWebHistory(),
  routes,
})

export default router