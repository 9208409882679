const alchemyKey = "https://eth-goerli.g.alchemy.com/v2/4o6bzNcjg1HE4Z1mTUSNiktqTgZYgf3d" //"https://eth-mainnet.alchemyapi.io/v2/06TjEVJYYTgNryGP7Awp9F9HqnccVp0z";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
const web3 = createAlchemyWeb3(alchemyKey);

export const isNFTClaimed = async (tokenId, campaign, address) => {
  // console.log("JS version - isNFTClaimed")
  const response = await fetch('https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?tokenId=' + tokenId + "&campaign=" + campaign + "&address=" + address, {
    method: 'GET'
  }).then(response => response.json())
    .catch(err => console.error(err));
  return response.claimed;
};

// will be used for custom contract collections
export const displayNFTs = async (address, contractAddress, campaign, claimCheckAPIEndpoint) => {
  const nfts = (await web3.alchemy.getNfts({ owner: address, contractAddresses: ["0x730b6e13E58a0e21d85F1e978b4422EB2da63157"] })).ownedNfts;
  // nfts.push(nfts[0])
  return Promise.all(nfts.map(async (nft) => {
    const tokenId = parseInt(nft.id.tokenId);
    const savedCookie = getCookie("token|" + tokenId + "|contract|" + contractAddress + "|campaign|" + campaign);
    let claimed;
    if (savedCookie === 'true') {
      claimed = savedCookie;
    } else {
      const claimStatus = await fetch(claimCheckAPIEndpoint + "tokenId=" + tokenId + "&address=" + contractAddress + "&campaign=" + campaign)
        .then((response) => response.json());
      // console.log("displayNFTs claimStatus: ", claimStatus)
      claimed = claimStatus.claimed;
      document.cookie = "token|" + tokenId + "|contract|" + contractAddress + "|campaign|" + campaign + "=" + claimed;
    }
    nft = { ...nft, claimed };
    // console.log("displayNFTs nfts: " + JSON.stringify(nft));
    return nft;
  }));
}


export const displayFAMNFTs = async (address, nftContractAddressFAM, nftContractAddressArtpocalypse) => {
  // const contractAddressArtpocalyseCollection = "0x384A0191Bf49aB791613Ff461cb866Dad189bf15" //change me
  const campaign = "artpocalyse"
  const nfts = (await web3.alchemy.getNfts({ owner: address, contractAddresses: [nftContractAddressFAM] })).ownedNfts;

  const ABI = [{ "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "famClaimed", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" }]



  const nftContract = new web3.eth.Contract(ABI, nftContractAddressArtpocalypse)
  return Promise.all(nfts.map(async (nft) => {
    const tokenId = parseInt(nft.id.tokenId);
    const savedCookie = getCookie("token|" + tokenId + "|contract|" + nftContractAddressArtpocalypse + "|campaign|" + campaign);
    // console.log("savedCookie = " + savedCookie)
    let claimed;
    if (savedCookie === 'true') {
      claimed = savedCookie;
    } else {
      const claimStatus = await nftContract.methods.famClaimed(tokenId).call().catch(() => { return false }) ? { tokenId, claimed: true } : { tokenId, claimed: false }
      console.log("token ", tokenId, " - claimStatus: ", claimStatus)
      claimed = claimStatus.claimed;
      document.cookie = "token|" + tokenId + "|contract|" + nftContractAddressArtpocalypse + "|campaign|" + campaign + "=" + claimed;
    }
    nft = { ...nft, claimed };

    return nft;
  }));
}





export const displayWhitelist = async (address, contractAddress, campaign, claimCheckAPIEndpoint) => {
  // const claimStatus = await fetch(claimCheckAPIEndpoint + "tokenId=" + address + "&address=" + contractAddress + "&campaign=" + campaign)
  const savedCookie = getCookie("token|" + address + "|contract|" + contractAddress + "|campaign|" + campaign);
  let claimed;
  if (savedCookie === 'true') {
    claimed = savedCookie;
  } else {
    const claimStatus = await fetch(claimCheckAPIEndpoint + "tokenId=" + address + "&address=" + contractAddress + "&campaign=" + campaign)
      .then((response) => response.json());
    claimed = claimStatus.claimed;
    document.cookie = "token|" + address + "|contract|" + contractAddress + "|campaign|" + campaign + "=" + claimed;
  }
  return claimed;
}


// will be used for OpenSea shared contract collections when redeeming 1155 1/1's
export const displayOpenSeaNFTs = async (address, campaign, claimCheckAPIEndpoint, openSeaCollectionSlug) => {
  const nfts = await (await fetch("https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/opensea-asset-lookup-api?address=" + address + "&collection=" + openSeaCollectionSlug)).json();

  return Promise.all(nfts.map(async (nft) => {
    // const tokenId = parseInt(nft.tokenId);
    const tokenId = nft.tokenId.toString();
    const savedCookie = getCookie("token|" + tokenId + "|contract|" + "0x495f947276749Ce646f68AC8c248420045cb7b5e" + "|campaign|" + campaign);
    let claimed;
    if (savedCookie === 'true') {
      claimed = savedCookie;
    } else {
      const claimStatus = await fetch(claimCheckAPIEndpoint + "tokenId=" + tokenId + "&address=" + "0x495f947276749Ce646f68AC8c248420045cb7b5e" + "&campaign=" + campaign)
        .then((response) => response.json());
      claimed = claimStatus.claimed;
      document.cookie = "token|" + tokenId + "|contract|" + "0x495f947276749Ce646f68AC8c248420045cb7b5e" + "|campaign|" + campaign + "=" + claimed;
    }
    nft = { ...nft, claimed };
    return nft;
  }));
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


export const shortenAddress = (address) => {
  // console.log("shortenAddress - address: ", address)
  if (!address) {
    return null;
  }
  return address.substring(0, 6) + "..." + address.substring(38)
}

export const connectWallet = async () => {
  if ((window).ethereum) {
    try {
      const addressArray = await (window).ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "Wallet connected",
        address: addressArray[0],
        shortAddress: shortenAddress(addressArray[0])
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
        statusCode: "ERROR"
      };
    }
  } else {
    return {
      address: "",
      status: ""
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if ((window).ethereum) {
    try {
      const addressArray = await (window).ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
        statusCode: "ERROR"
      };
    }
  } else {
    return {
      address: "",
      status: ""
    };
  }
};