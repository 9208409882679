<script setup lang="ts">
import { reactive } from 'vue';
import { displayFAMNFTs } from "../scripts/web3utils.js";
import CollectionViewer from "../components/CollectionViewer/CollectionViewerFineartmfers.vue";
import { useBoard, useEthers, useWallet, useEthersHooks, shortenAddress } from 'vue-dapp'

const { open } = useBoard()
const { onActivated, onChanged, onDeactivated } = useEthersHooks()
const { address, /*balance,*/ isActivated } = useEthers()

let connectBtnValue = reactive({ value: "Connect" });
let fullAddress = reactive({ value: '' });
let ownedNFTs: any = reactive({ list: [] });
let hasConnected = reactive({ value: false })

const nftContractAddressArtpocalypse = "0x5291d7972a1F1b019A0B2faE05597Ac90Cbc4132"
const nftContractAddressFAM = "0x384A0191Bf49aB791613Ff461cb866Dad189bf15"

const {
    disconnect,
    wallet
} = useWallet();

onActivated(() => {
    connectBtnValue.value = shortenAddress(address.value);
    fullAddress.value = address.value;
    displayFAMNFTs(fullAddress.value, nftContractAddressFAM, nftContractAddressArtpocalypse)
        .then((list: any) => {
            ownedNFTs.list = [...list];
            hasConnected.value = true;
        });
})

onChanged(() => {
    connectBtnValue.value = shortenAddress(address.value);
    fullAddress.value = address.value;
    displayFAMNFTs(fullAddress.value, nftContractAddressFAM, nftContractAddressArtpocalypse)
        .then((list: any) => {
            ownedNFTs.list = [...list];
            hasConnected.value = true;
        });
})

onDeactivated(() => {
    ownedNFTs.list = [];
    hasConnected.value = false;
    fullAddress.value = "";
})

const onCollectionRefresh = async () => {
    // need to update this displayNFTs for OpenSea projects
    hasConnected.value = false;
    await displayFAMNFTs(fullAddress.value, nftContractAddressFAM, nftContractAddressArtpocalypse)
        .then((list: any) => {
            ownedNFTs.list = [...list];
            hasConnected.value = true;
        });
}
</script>

<template>
    <header>


        <div class="navbarContainer">
            <div class="navbarWrapper">
                <div class="navbarLogo">
                    <a href="https://metavate.io" target="_blank">
                        <img src="../assets/imgs/Metavate.png" />
                    </a>
                </div>
                <div class="navbarMenu">


                    <div class="connectBtnContainer">
                        <!-- CONNECT BUTTON -->
                        <!-- <button class="connect-wallet-navBtn" @click="handleConnectClick">{{ connectBtnValue.value }}</button> -->
                        <div v-if="isActivated" class="connectBtnWrapper">
                            <!-- Account -->
                            <!-- <div class="">
                        {{ shortenAddress(address) }}
                    </div> -->

                            <div class="hidden">
                                <!-- <div class="px-1 mr-1">{{ displayEther(balance) }} ETH</div> -->
                                <div class="">
                                    {{ shortenAddress(address) }}
                                </div>
                            </div>
                            <div>
                                <button @click="disconnect" class="collection-item-unselect">Disconnect</button>
                            </div>
                        </div>


                        <button v-else @click="open()" class="collection-item-select"
                            :disabled="wallet.status === 'connecting'">
                            {{
                            wallet.status === 'connecting'
                                ? 'Connecting...'
                                : wallet.status === 'loading'
                                    ? 'Loading...'
                                    : 'Connect'
                        }}
                        </button>

                        <!-- END -->
                    </div>

                </div>
            </div>
        </div>
    </header>


    <CollectionViewer :list="ownedNFTs.list" :address="fullAddress.value" :hasConnected="hasConnected.value"
        @collectionRefresh="onCollectionRefresh" />
    <section>
        <div class="footer2">
            <div class="section-margin2"></div>
            <p class="metavate-p-JA">Powered by</p>
            <a href="https://metavate.io" target="_blank" rel="noopener">
                <img class="metavate-JA" src="../assets/imgs/Metalogo.png" alt="Metavate" />
            </a>
        </div>
    </section>
</template>

<style>
@import "../assets/base.css";
@import "../main.css";
@import "../styles/brand.css";


/* Page Footer  */
.footer2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
    bottom: 0;
    /* position: absolute; */
    width: 100%;
    padding-bottom: 10px;
}

.metavate-p-JA {
    color: #f4f4f4;
    padding-left: 10px;
    position: relative;
    top: 1rem;
}

.metavate-JA {
    position: relative;
    display: block;
    height: 50px;
    filter: invert(1);

}

.os-button {
    height: 46.4px;
    width: 46.4px;
    padding: 0px;
}
</style>