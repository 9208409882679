<script setup lang="ts">
/* eslint-disable */
import { reactive, onMounted, ref } from 'vue';

import { useBoard, useEthers, useWallet, useEthersHooks, shortenAddress } from 'vue-dapp'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as React from 'react';

// UI
import { BellRing, Search, Undo2 } from 'lucide-vue-next'
import { Separator } from '../../components/ui/separator'
import { Button } from '../../components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '../../components/ui/card'
import { Switch } from '../../components/ui/switch'
import { Input } from '../../components/ui/input';
import { cn } from '../../lib/utils'
import { Alert, AlertDescription } from '../../components/ui/alert'

// Define a reactive variable to control button disable state
const isSubmitting = ref(false);

const {
    disconnect,
    wallet
} = useWallet();
const { onActivated, onChanged, onDeactivated } = useEthersHooks()
const { address, /*balance,*/ isActivated } = useEthers()


import Countdown from '../components/CountdownTimer.vue'
import { createAlchemyWeb3 } from "@alch/alchemy-web3";


import { http } from 'viem'
import { mainnet } from 'viem/chains'
import { createEnsPublicClient } from '@ensdomains/ensjs'
import ClipboardJS from 'clipboard';

// Create the client
const client = createEnsPublicClient({
    chain: mainnet,
    transport: http(),
})

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/06TjEVJYYTgNryGP7Awp9F9HqnccVp0z"; //"https://eth-goerli.g.alchemy.com/v2/4o6bzNcjg1HE4Z1mTUSNiktqTgZYgf3d" //
const web3alchemy = createAlchemyWeb3(alchemyKey);
// let connectBtnValue = reactive({ value: "Connect" });
let hasConnected = reactive({ value: false });
const hasRaffleId = new URLSearchParams(window.location.search).get("raffleId") !== null;
const raffleId = hasRaffleId ? new URLSearchParams(window.location.search).get("raffleId") : null
let loadedRaffle = reactive({ value: !hasRaffleId });
const currentTime = new Date().getTime();
let raffleDetails = reactive({ value: null });

const postMessage = ref("");
let isSuccessMessage = ref(false);



let ownedNFTs: any = reactive({ list: [] });
const { open } = useBoard()

const enterRaffle = async () => {
    if (!address.value) {
        open()
        // postMessage.value = "Connect wallet to submit your raffle.";
        return;
    }
    postMessage.value = "Sign a message from your wallet to enter the raffle...";
    const nfts = await web3alchemy.alchemy.getNfts({ owner: address.value, contractAddresses: [raffleDetails.value.contract] })

    console.log("enterRaffle nfts: ", nfts)
    let tokenIds: number[] = []

    nfts.ownedNfts.forEach(nft => {
        tokenIds.push(parseInt(nft.id.tokenId))
    })

    if (tokenIds.length < 1) {
        postMessage.value = "No eligible NFTs found in this wallet";
        return;
    }
    // alert(tokenIds)

    //   const wallet = address.value
    let messageSignature;
    try {
        const wallet = address.value
        messageSignature = await wallet.provider.request({
            method: "personal_sign",
            params: ["verify wallet: " + address.value + "\r\nclaiming token(s): " + tokenIds, address.value]
        });

        postMessage.value = "Submitting entry...";
    } catch (error: any) {
        console.log("subitting entry catch...", error)
        error = JSON.parse(error.message)
        postMessage.value = error.message;
        return
    }

    fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/submit-raffle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            wallet: address.value,
            messageSignature,
            raffleId,
            tokenIds: tokenIds.toString()
        }),
    }).then((claimed) => {
        console.log("submitRaffle.then(claimed): ", claimed)
        if (claimed?.status === 200) {

        } else {
        }

        claimed?.json().then(data => {
            postMessage.value = data.message
        })
    });
}

const displayAddress = async (address: any) => {
    if (!address) {
        return address
    }
    // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
    const ensName = await client.getName({ address })
    if (ensName) {
        return ensName.name + " (" + shortenAddress(address) + ")"
    }

    return shortenAddress(address);
}

const finishedClaimProcess = ref("")
const setPostLoadingScreen = ref(false)
const setLoadingScreen = ref(false)
// const cancelClaimModal = ref(false)
const tokenIds = ref("")
const postLoadingScreen = ref(false)
const loadingScreen = ref(false)
// const claimModal = ref(false)

const submitRaffle = async (tokenIds: any) => {

    if (!tokenIds) {
        postMessage.value = "Please select a token to claim."
        return
    }
    if (!wallet.value) {
        postMessage.value = "Please connect your wallet."
        return
    }
    setLoadingScreen.value = true
    setPostLoadingScreen.value = true
    //   claimModal.value = true
    postLoadingScreen.value = true
    loadingScreen.value = true
    console.log(tokenIds)
    const wallet = address.value
    let messageSignature;
    try {

        messageSignature = await wallet.provider.request({
            method: "personal_sign",
            params: ["verify wallet: " + address.value + "\r\nclaiming token(s): " + tokenIds, address.value]
        });

        postMessage.value = "Submitting entry...";
    } catch (error: any) {
        console.log("subitting entry catch...", error)
        error = JSON.parse(error.message)
        // if (error.code == 4001) {
        //   alert(error.message)
        // }
        postMessage.value = error.message;
        return
    }

    fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/submit-raffle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            wallet: address.value,
            messageSignature,
            raffleId,
            tokenIds: tokenIds.toString()
        }),
    }).then((claimed) => {
        console.log("submitRaffle.then(claimed): ", claimed)
        // setPostLoadingScreen.value = false
        setLoadingScreen.value
    })
}




onActivated(() => {
    // console.log("onActivated..")
})

onChanged(() => {
    if (wallet.provider.accounts) {
        address.value = wallet.provider.accounts[0];
    }
})

onDeactivated(() => {
    hasConnected.value = false;
})

const registerClick = async () => {
    // this.preventDefault();
    // alert()
    console.log("registerClick...")
    console.log("wallet:", wallet)
    console.log("address:", address)
    console.log("address.value:", address.value)

    // Prevent multiple clicks
    if (isSubmitting.value) return;

    // Set isSubmitting to true to disable the button
    isSubmitting.value = true;




    if (!wallet.provider) {
        open()
        postMessage.value = "Please connect your wallet."
        return
    }

    let messageSignature;
    const timestamp = new Date().getTime()
    const email = document.getElementById("email").value


    if (!email) {
        postMessage.value = "🚨 A contact email is required to register."
        return
    }
    try {
        postMessage.value = "Sign the message to verify your wallet..."
        messageSignature = await wallet.provider.request({
            method: "personal_sign",
            params: ["register wallet: " + address.value + "\r\ntimestamp: " + timestamp + "\r\nmetavate raffles", address.value]
        });

        postMessage.value = "Submitting...";
    } catch (error: any) {
        console.log("subitting entry catch...", error)
        error = JSON.parse(error.message)
        postMessage.value = error.message;
        return
    }

    fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/register-raffle-account', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            wallet: address.value,
            email,
            timestamp,
            signature: messageSignature
        }),
    }).then((claimed) => {
        console.log("submitRaffle.then(claimed): ", claimed)

        // Reset isSubmitting to false after API call completes
        isSubmitting.value = false;

        if (claimed?.status === 200) {
            isSuccessMessage.value = true;
            // Show popup with options
            // showPopup();
        } else {
            isSuccessMessage.value = false;
        }

        claimed?.json().then(data => {
            if (data.status == "success") {
                postMessage.value = "Success! 🎊 Your account comes preloaded with 1 credit to create a raffle. View account plans for additional credits and options."
                isSuccessMessage.value = true;
            } else {
                postMessage.value = data.message
                isSuccessMessage.value = false;
            }
            //data.message
        })
    });
};



const navigateToRaffleCreate = () => {
    window.location.href = '/create-raffle';
};

const navigateToRaffleCreate1155 = () => {
    window.location.href = '/create-raffle-1155';
};


const navigateToRaffleHome = () => {
    window.location.href = '/raffle-home';
};

</script>


<template>
   <div class="SignUpContainer w-full h-full flex  lg:flex-row flex-wrap justify-between items-center align-middle">
    <div class="leftSection flex flex-col justify-center items-center align-middle w-full md:w-[50%] px-4">
        <Card :class="cn('flex flex-col border-[#141414] bg-black/50 rounded-lg w-full md:w-[450px] m-auto px-4')">
            <CardHeader :class="cn('flex flex-col m-auto text-center')">
                <CardTitle :class="cn('text-3xl')">Register Account</CardTitle>
                <CardDescription :class="cn('opacity-60')">Create a trial account</CardDescription>
            </CardHeader>
            <CardContent>
                <CardDescription>Enter an email to register your account and receive a free credit. You will also need to sign a message from a wallet to validate the raffle was created by you.
                </CardDescription>
                <form :class="cn('w-full mt-4')" @submit.prevent="registerClick()">
                    <Input type="email" id="email" name="email" placeholder="Email" />
                    <Button :class="cn('w-full mt-8', 'border border-white bg-black/50')" variant="default" type="submit" :disabled="isSubmitting">
                        Register
                    </Button>
                </form>
                <Alert v-if="postMessage"
                    :class="cn('mt-4 flex rounded-sm justify-center text-center mx-auto w-full')"
                    id="register-message">
                    <AlertDescription>
                        <span :class="cn('border-1', {
                'bg-red text-yellow-400': !isSuccessMessage,
                'bg-green text-green-500': isSuccessMessage,
            })">
                            {{ postMessage }}
                        </span>
                    </AlertDescription>
                </Alert>
            </CardContent>
            <CardFooter>
                <p class="text-sm opacity-60">After registration, select a raffle to create: </p>
                <div class="flex flex-row gap-4 mx-auto w-full justify-center items-center align-middle text-center mt-8">
                    <Button variant="outline" class="hover:bg-white hover:text-black" @click="navigateToRaffleCreate"
                        >
                        Create ERC-721 Raffle
                    </Button>
                    <Button variant="outline" class="hover:bg-white hover:text-black" @click="navigateToRaffleCreate1155 "
                       >
                        Create ERC-1155 Raffle
                    </Button>
                
                </div>
            </CardFooter>
        </Card>
    </div>
    <div class="rightSection h-auto lg:h-full bg-slate-700 flex flex-col lg:flex-row justify-center items-center align-middle w-full lg:w-[50%]">
    <div class="background-image object-cover h-full lg:w-[100%] lg:flex lg:justify-center lg:items-center lg:relative">
        <img class="hidden lg:block object-cover h-full w-full" src="https://www.itl.cat/pngfile/big/145-1452213_comet-minimal-minimal-wallpaper-comet.jpg" />
 
    </div>
</div>
</div>


</template>

<style></style>