<script setup lang="ts">
// import { ref } from 'vue'


import { reactive/*, ref*/ } from 'vue';
import {/*connectWallet,*/ displayOpenSeaNFTs} from "../scripts/web3utils.js";
import CollectionViewer from "../components/CollectionViewer/CollectionViewerJustinAversano.vue";

// import useWallet from '../hooks/useWallte';

import "../temp.css"


import { /*useBoard,displayEther,useWallet,*/ useEthers,   useEthersHooks, shortenAddress } from 'vue-dapp'
// const { open } = useBoard()
const { address/*, balance, isActivated */} = useEthers()

const { onActivated, onChanged/*, onDeactivated*/ } = useEthersHooks()

let connectBtnValue = reactive({ value: "Connect" });
let fullAddress = reactive({ value: '' });
let ownedNFTs: any = reactive({ list: [] });
let hasConnected = reactive({value: false})
// const openSeaAddress = "0x495f947276749Ce646f68AC8c248420045cb7b5e"
 
// const {
  // onConnect,
  // connected,
  // web3,
  // userAddress,
  // chainId,
  // networkId,
  // resetApp,
  // assets,
  // getAccountAssets,
  // walletObj,
  // disconnect, 
  // wallet
// } = useWallet();

// const handleConnectClick = async () => {
//   console.log("start handle connect connected: ", connected)
//   if ((connected as any)._object.userAddress) {
//     resetApp(connectBtnValue)
//     return
//   }

//   // await onConnect();
//   if (connected) {
//     console.log("setting window.ethereum: ", web3)
//     window.ethereum = web3
//   } else {
//     console.log('not connected')
//   }
//   console.log("123123123")
//   connectBtnValue.value = shortenAddress(address.value);
//   fullAddress.value = address.value;
//     await displayOpenSeaNFTs(address.value, "test", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "beepboopthisisatest")
//     // await displayOpenSeaNFTs(address.value, "tarotscrolls", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "justinaversano-gabbagallery")
//     .then((list: any) => {    
//       ownedNFTs.list = [...list];
//       hasConnected.value = true;
//   });
// }


onActivated(() => {
  console.log("activated... ", address.value)
  
  connectBtnValue.value = shortenAddress(address.value);
    fullAddress.value = address.value;
    // await displayOpenSeaNFTs(address.value, "tarotscrolls", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "justinaversano-gabbagallery").then((list: any) => {    ownedNFTs.list = [...list];
    // displayOpenSeaNFTs(address.value, "tarotscrolls", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "justinaversano-gabbagallery")
   displayOpenSeaNFTs(address.value, "test", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "beepboopthisisatest")
    .then((list: any) => {    
      ownedNFTs.list = [...list];
      hasConnected.value = true;
  });
})


onChanged(() => {
  console.log("changed... ", address.value)
  
  connectBtnValue.value = shortenAddress(address.value);
    fullAddress.value = address.value;
    // await displayOpenSeaNFTs(address.value, "tarotscrolls", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "justinaversano-gabbagallery").then((list: any) => {    ownedNFTs.list = [...list];
    // displayOpenSeaNFTs(address.value, "tarotscrolls", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "justinaversano-gabbagallery")
   displayOpenSeaNFTs(address.value, "test", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "beepboopthisisatest")
    .then((list: any) => {    
      ownedNFTs.list = [...list];
      hasConnected.value = true;
  });
})

const onCollectionRefresh = async () => {
  // need to update this displayNFTs for OpenSea projects
    hasConnected.value = false;
    // await displayOpenSeaNFTs(address.value, "test", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "beepboopthisisatest")
  await displayOpenSeaNFTs(address.value, "tarotscrolls", "https://pt15347s1j.execute-api.us-east-1.amazonaws.com/default/nftredeem-claim-check-api?", "justinaversano-gabbagallery")
  .then((list: any) => {   
  hasConnected.value = true;

    ownedNFTs.list = [...list];
  });
}

</script>

<template>
    <div class="section-margin"></div>
    <div class="section-margin"></div>
    <header>
  
      <nav class="router-menu">
        <div class="navBtn-menu">
          <a href="/"><button>Home</button></a>
          <!-- <a href="#/check"><button>Claim Check</button></a>
          <a href="#/unclaimed"><button>Browse Unclaimed</button></a>
          <a href="https://opensea.io/collection/justinaversano-gabbagallery" rel="noopener noreferrer" target="_blank"><img
              class="os-button" src="../assets/imgs/opensea.png" /></a>
  -->
        </div>
        <!--
        <div v-if="isActivated" class="flex items-center flex-col">
          <div class="sm:hidden py-2 px-3 rounded-2xl inline-block bg-gray-100">
            {{ shortenAddress(address) }}
          </div>
  
          <div class="hidden sm:flex py-1 px-2 items-center rounded-3xl border border-solid">
            <div class="px-1 mr-1">{{ displayEther(balance) }} ETH</div>
            <div class="py-2 px-3 rounded-2xl inline-block bg-gray-100">
              {{ shortenAddress(address) }}
            </div>
          </div>
          <div>
            <button @click="disconnect" class="text-sm border rounded-3xl px-2">Disconnect</button>
          </div>
        </div>
  
        <button v-else @click="open()" class="btn" :disabled="wallet.status === 'connecting'">
          {{
            wallet.status === 'connecting'
            ? 'Connecting...'
            : wallet.status === 'loading'
              ? 'Loading...'
              : 'Connect'
          }}
        </button> -->
        
        <br/><br/><br/><br/>
      </nav>
  
  
    </header>
  
    <CollectionViewer :list="ownedNFTs.list" :address="fullAddress.value" :hasConnected="hasConnected.value"
      @collectionRefresh="onCollectionRefresh" />
    <section>
      <div class="footer2">
        <div class="section-margin2"></div>
        <p class="metavate-p-JA">Powered by</p>
        <a href="https://metavate.io" target="_blank" rel="noopener">
          <img class="metavate-JA" src="../assets/imgs/Metalogo.png" alt="Metavate" />
        </a>
      </div>
    </section>
  </template>
  
  

  