<script setup lang="ts">

</script>

<template>
  <div class="   bg-[#0e0e0e]  px-4 md:px-8 py-0 flex justify-center items-center border-t border-[#ffffff20] z-10">
    <a href="https://metavate.io" target="_blank">
      <img class="w-[250px]" src="../assets/imgs/Metavate.png" alt="Metavate Logo" />
    </a>
  </div>
</template>
     
<style>

</style>
  