<script setup lang="ts">

/* eslint-disable */
import { reactive, onMounted, ref } from 'vue';
// import { shortenAddress, getNFTs } from "@/scripts/web3utils.js";

import { useBoard, useEthers, useWallet, useEthersHooks, shortenAddress } from 'vue-dapp'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as React from 'react';

// UI
import { BellRing, Search, Copy } from 'lucide-vue-next'
import { Separator } from '../../components/ui/separator'
import { Button } from '../../components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs'
import { Switch } from '../../components/ui/switch'
import { cn } from '../../lib/utils'

import Vue3Odometer from 'vue3-odometer'
import 'odometer/themes/odometer-theme-default.css'



const {
  disconnect,
  wallet
} = useWallet();
const { onActivated, onChanged, onDeactivated } = useEthersHooks()
const { address, /*balance,*/ isActivated } = useEthers()

// import useWallet from '@/hooks/useWallte';

import Countdown from '../../components/CountdownTimer.vue'
import { createAlchemyWeb3 } from "@alch/alchemy-web3";


import { http } from 'viem'
import { mainnet } from 'viem/chains'
import { createEnsPublicClient } from '@ensdomains/ensjs'
import ClipboardJS from 'clipboard';


// Create the client
const client = createEnsPublicClient({
  chain: mainnet,
  transport: http(),
})

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/06TjEVJYYTgNryGP7Awp9F9HqnccVp0z";
const web3alchemy = createAlchemyWeb3(alchemyKey);
let connectBtnValue = reactive({ value: "Connect" });
let fullAddress = reactive({ value: '' });
let hasConnected = reactive({ value: false });
const hasRaffleId = new URLSearchParams(window.location.search).get("raffleId") !== null;
const raffleId = hasRaffleId ? new URLSearchParams(window.location.search).get("raffleId") : null
let loadedRaffle = reactive({ value: !hasRaffleId });
const currentTime = new Date().getTime();
let raffleDetails = reactive({ value: null });

const postMessage = ref("");



let ownedNFTs: any = reactive({ list: [] });
const { open } = useBoard()



// don't collect walllet NFTs, just enter the raffle and do token lookup server side

const enterRaffle = async () => {
  if (!address.value) {
    open()
    // postMessage.value = "Connect wallet to submit your raffle.";
    return;
  }
  postMessage.value = "Sign a message from your wallet to enter the raffle...";

  // alert(tokenIds)

  //   const wallet = address.value
  let messageSignature;
  try {

    messageSignature = await wallet.provider.request({
      method: "personal_sign",
      params: ["verify wallet: " + address.value + "\r\nentering ERC1155 raffle: " + raffleId, address.value]
    });

    postMessage.value = "Submitting entry...";
  } catch (error: any) {
    console.log("subitting entry catch...", error)
    error = JSON.parse(error.message)
    // if (error.code == 4001) {
    //   alert(error.message)
    // }
    postMessage.value = error.message;
    return
  }

  fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/submit-raffle-1155', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      wallet: address.value,
      messageSignature,
      raffleId,
      // tokenIds: tokenIds.toString()
    }),
  }).then((claimed) => {
    // console.log("submitRaffle.then(claimed): ", claimed)
    // setPostLoadingScreen.value = false
    // setLoadingScreen.value = false
    if (claimed?.status === 200) {
      // cancelClaimModal();
      // finishedClaimProcess.value = 'success';
    } else {
      // cancelClaimModal();
      // finishedClaimProcess.value = 'error';
    }

    claimed?.json().then(data => {
      postMessage.value = data.message
      raffleDetails.value.raffleEntries += data.entries
    })
  }, () => {
    // setPostLoadingScreen.value = false
    // setLoadingScreen.value = false
    // cancelClaimModal();
    // finishedClaimProcess.value = 'error';
  });
}

const displayAddress = async (address: any) => {
  if (!address) {
    return address
  }
  // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
  const ensName = await client.getName({ address })
  if (ensName) {
    return ensName.name + " (" + shortenAddress(address) + ")"
  }

  return shortenAddress(address);
}

const finishedClaimProcess = ref("")
const setPostLoadingScreen = ref(false)
const setLoadingScreen = ref(false)
const cancelClaimModal = ref(false)
const tokenIds = ref("")
const postLoadingScreen = ref(false)
const loadingScreen = ref(false)
const claimModal = ref(false)

onActivated(() => {
  connectBtnValue.value = shortenAddress(address.value);
  fullAddress.value = address.value;
})

onChanged(() => {
  connectBtnValue.value = shortenAddress(address.value);
  fullAddress.value = address.value;
})

onDeactivated(() => {
  ownedNFTs.list = [];
  hasConnected.value = false;
  fullAddress.value = "";
})

onMounted(async () => {
  console.log("onMounted...")
  if (!hasRaffleId) {
    loadedRaffle.value = true;
    return;
  }

  var response: any = await fetch("https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/get-raffle-1155?raffleId=" + raffleId);
  response = await response.json();
  raffleDetails.value = response.results;
  console.log("raffleDetails: ", raffleDetails.value)
  // console.log(raffleDetails)
  var creatorAddress = await displayAddress(raffleDetails.value.creator)
  // console.log("creatorAddress:", creatorAddress)
  // console.log(raffleDetails.value.creator)
  raffleDetails.value.creator = creatorAddress;
  // console.log(raffleDetails.value.creator)

  // setInterval(() => { 
  //   raffleDetails.value.raffleEntries += 5
  //   console.log(raffleDetails.value.raffleEntries)
  // }, 10000)



  // const ABI = [{"inputs":[{"internalType":"uint256","name":"","type":"uint256"}],"name":"famClaimed","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"view","type":"function"}]
  var nameAbi: any = [{
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }]


  const nftContract = new web3alchemy.eth.Contract(nameAbi, raffleDetails.value.contract)
  console.log("nftContract name...")
  var contractName = ""
  try {
    contractName = await nftContract.methods.name().call()
  } catch (e) {

  }


  console.log("nftContract name: ", contractName)
  raffleDetails.value.contractName = contractName
  setTimeout(() => { loadedRaffle.value = true; }, 750)

  console.log("onMounted done.")
})





const copyToClipboard = () => {
  const fullWinnerAddress = raffleDetails.value.winner;

  // Create a temporary element to copy text to the clipboard
  const tempInput = document.createElement('input');
  tempInput.value = fullWinnerAddress;
  document.body.appendChild(tempInput);

  // Select the text and copy it to the clipboard
  tempInput.select();
  document.execCommand('copy');

  // Remove the temporary element
  document.body.removeChild(tempInput);

  // You can add a notification or any other feedback here
  alert(`Address ${fullWinnerAddress} copied to clipboard!`);
};

// Initialize ClipboardJS
new ClipboardJS('.copyButton');

const navigateToRaffleHome = () => {
    window.location.href = '/raffle-1155';
};


</script>


<template>
  <div class="flex flex-col mx-auto w-full text-center">

    <!-- RAFFLE SCREEN -->
    <div v-if="hasRaffleId">
      <!-- Loading Spinner -->
      <div v-if="!loadedRaffle.value" class="Spinner">
        Searching for ERC1155 raffle {{ raffleId }}
        <br />
        <!-- <img class="loader" src="../assets/imgs/Loading.gif" /> -->
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <!-- RAFFLE MODULE -->
      <div v-if="loadedRaffle.value">

        <!-- STATUS CONTAINER -->
        <div class="">
          <!-- 🏁 EXPIRED RAFFLE -->
          <div class="" v-if="raffleDetails.value.endtime < currentTime">
            <Card :class="cn('border-0')">
              <CardHeader>
                <CardTitle :class="cn('text-red')">Raffle has expired</CardTitle>
                <CardDescription :class="cn('text-3xl')"> {{ (new
                  Date(raffleDetails.value.endtime)).toLocaleString() }}</CardDescription>
              </CardHeader>
            </Card>
          </div>

          <!-- ❌ NOT STARTED RAFFLE-->
          <div class="" v-else-if="raffleDetails.value.starttime > currentTime">
            <Card :class="cn('border-0')">
              <CardHeader>
                <CardTitle :class="cn('text-red')">Raffle has not started yet</CardTitle>
                <CardDescription :class="cn('text-3xl')">
                  <Countdown :date="new Date(raffleDetails.value.endtime)"></Countdown>
                </CardDescription>
              </CardHeader>
            </Card>
          </div>

          <!-- 🪐 ACTIVE RAFFLE-->
          <div class="" v-else>
            <Card :class="cn('border-0')">
              <CardHeader>
                <CardTitle :class="cn('text-red')">ERC1155 Raffle has started</CardTitle>
                <CardDescription :class="cn('text-3xl')">
                  <Countdown :date="new Date(raffleDetails.value.endtime)"></Countdown>
                </CardDescription>
              </CardHeader>
            </Card>
          </div>
        </div>

        <!-- RAFFLE CONTAINER -->
        <Card :class="cn('w-[95%] md:w-[1000px] py-8 mx-auto flex flex-1 flex-col md:flex-row gap-3  border-0 ')">
          <!-- LEFT SIDE -->
          <Card
            :class="cn('w-full md:w-[30%]  md:pt-0 pt-60 md:justify-start md:align-top md:items-start sm:justify-center sm:align-middle sm:items-center sm:flex border-0 ')">
            <Card :class="cn('  border-0')">
              <div class="h-[400px] w-full rounded-lg overflow-hidden">
                <img :src="raffleDetails.value.rewardimage" class="h-full w-full object-contain border-[#ffffff10]" />
              </div>
            </Card>
          </Card>
          <!-- RIGHT SIDE -->
          <Card
            :class="cn('flex-1 flex flex-col gap-3 justify-between rounded-lg border border-[#ffffff20] bg-[#010a0ff3] text-white')">
            <CardHeader :class="cn('justify-between text-left flex flex-col md:flex-row border-b-[#FFFFFF30] border-b')"
              v-if="hasRaffleId">
              <div class="">
                <CardDescription>Raffle ID : <strong> {{ raffleId }} </strong></CardDescription>
                <CardTitle>
                  <CardDescription :class="cn('text-2xl tracking-wide font-bold text-green-500')">Total Entries:
                    <!-- <strong :class="cn('text-2xl tracking-wide text-white font-normal')">{{ raffleDetails.value.raffleEntries }}</strong> -->
                    <!-- <Odometer value={{ raffleDetails.value.raffleEntries.toString() }} format="(.ddd),dd" /> -->
                    <Vue3Odometer class="text-2xl tracking-wide text-white font-normal o-text"
                      :value="raffleDetails.value.raffleEntries" />
                  </CardDescription>
                </CardTitle>
                <CardDescription> <strong>Created by:</strong> {{ raffleDetails.value.creator }}</CardDescription>
              </div>
              <div class="flex  flex-1 flex-col md:flex-row w-full justify-center items-center align-middle text-center">
                <div class=""
                  v-if="raffleDetails.value.starttime < currentTime && raffleDetails.value.endtime > currentTime">
                  <div @click="enterRaffle()">
                    <Button class="bg-gradient-to-r from-violet-600 to-indigo-600 text-2xl px-6 py-7">Enter</Button>
                  </div>
                  <div id="postMessage">{{ postMessage }}</div>
                </div>
              </div>
              <div
                class="bg-green-500 text-white px-6 py-2 flex gap-3 justify-center align-middle items-center flex-1 rounded-full"
                v-if="raffleDetails.value.endtime < currentTime">
                <Label :class="cn('text-white flex flex-row gap-3 justify-center align-middle items-center')">
                  <h3 :class="cn('text-white text-xl')">Winner </h3>
                  <div @click="copyToClipboard">
                    <Copy class="text-sm w-4 h-4 text-black cursor-pointer" />
                  </div>
                </Label>
                <h5>{{ shortenAddress(raffleDetails.value.winner) }}</h5>
              </div>





            </CardHeader>
            <CardContent :class="cn('w-full justify-between h-full text-left flex flex-col ')">
              <Tabs default-value="details" class="w-full">
                <TabsList>
                  <TabsTrigger value="details">
                    Details
                  </TabsTrigger>
                  <!-- <TabsTrigger value="entries">
                          Entries
                        </TabsTrigger> -->
                </TabsList>
                <Separator />
                <TabsContent :class="cn('w-full h-full flex flex-row justify-between')" value="details">
                  <div class="flex flex-col md:flex-row justify-between">
                    <Card :class="cn('w-full md:w-[100%] flex flex-col gap-2 border-0')">
                      <div class="flex flex-col">
                        <Label :class="cn('text-sm font-bold text-green-500')">End Time:</Label>
                        <CardDescription :class="cn('text-xl  ')">{{ (new
                          Date(raffleDetails.value.endtime)).toLocaleString(undefined, { timeZoneName: 'short' }) }}
                        </CardDescription>
                      </div>
                      <div class="flex flex-col">
                        <Label :class="cn('text-sm font-bold text-green-500')">NFT Contract</Label>
                        <CardDescription :class="cn('text-xl  ')">{{ raffleDetails.value.contractName ?
                          raffleDetails.value.contractName + " (" +
                          shortenAddress(raffleDetails.value.contract) + ")" :
                          shortenAddress(raffleDetails.value.contract)

                        }}</CardDescription>
                      </div>
                      <div class="flex flex-col">
                        <Label :class="cn('text-sm font-bold text-green-500')">Reward</Label>
                        <CardDescription :class="cn('text-lg ')">{{ raffleDetails.value.reward }}</CardDescription>
                      </div>
                      <div class="flex flex-col">
                        <Label :class="cn('text-sm font-bold text-green-500')">Eligible ERC1155 Token IDs</Label>
                        <CardDescription :class="cn('text-lg ')">{{ raffleDetails.value.tokens.replaceAll(",", ", ") }}
                        </CardDescription>
                      </div>
                      <div class="flex flex-col">
                        <Label :class="cn('text-sm font-bold text-green-500')">Raffle Snapshot Blocknumber</Label>
                        <CardDescription :class="cn('text-lg ')"><a target="_blank"
                            :href="'https://etherscan.io/block/' + raffleDetails.value.blocknumber">{{
                              raffleDetails.value.blocknumber }}</a></CardDescription>
                      </div>
                    </Card>
                    <Card :class="cn('w-full md:w-[50%] flex flex-col border-0 mt-4 md:mt-0')">
                      <div class="flex flex-col">
                        <Label :class="cn('text-sm font-bold text-green-500')">Description</Label>
                        <CardDescription :class="cn('text-lg ')">{{ raffleDetails.value.description }}
                        </CardDescription>
                      </div>
                    </Card>
                  </div>
                </TabsContent>

                <TabsContent value="entries">
                  Coming Soon
                </TabsContent>
              </Tabs>
            </CardContent>
            <CardFooter>




            </CardFooter>
          </Card>
        </Card>

        <Button variant="outline"
          class=" bg-white text-black hover:bg-black hover:text-white md:w-[300px] md:justify-center md:flex md:m-auto w-[95%] mb-4 "
          @click="navigateToRaffleHome" href="/raffle-home">
          <Undo2 :class="cn('mr-2 text-xs md:text-sm')" /> Search a new 1155 ID
        </Button>

      </div>

    </div>
    <!-- ❌ END RAFFLE SCREEN -->

    <!-- HOMESCREEN -->
    <div class="w-full flex flex-col justify-center items-center text-center align-middle" v-else>
      <div class="my-4 w-88">
        <h3 class="text-2xl">Welcome to <br />NFT Redeem Raffle System.</h3>
      </div>
      <Card :class="cn('w-full h-full justify-center align-middle items-center text-center m-auto border-0')">
        <Card :class="cn('w-[350px] mx-auto p-4 border-[#ffffff20] rounded-md')">
          <Label class="text-xl text-center"> Enter ERC-1155 Raffle ID</Label>
          <form class="flex flex-col " action="/raffle-1155/">
            <Input class="mt-4" type="number" name="raffleId" />
            <Button class="RaffleBtn mt-4" type="submit" value="Search"> Search </Button>
          </form>
        </Card>
      </Card>
      <a href="/raffle" class="mt-4 text-sm text-slate-300"  type="submit" value="Search"> Switch to ERC-721 Raffle </a>
    </div>
    <!-- ❌ END HOMESCREEN -->
  </div>
</template>
     
<style>
@import '../../styles/newRaffle.css'
</style>
  