<script setup lang="ts">

/* eslint-disable */
import { reactive, onMounted, ref } from 'vue';
// import { shortenAddress, getNFTs } from "@/scripts/web3utils.js";

import { useBoard, useEthers, useWallet, useEthersHooks, shortenAddress } from 'vue-dapp'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as React from 'react';
import { cn } from "../../lib/utils"


// UI
import { BellRing, Search } from 'lucide-vue-next'
import { Separator } from '../../components/ui/separator'
import { Button } from '../../components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '../../components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs'


const {
    disconnect,
    wallet
} = useWallet();
const { onActivated, onChanged, onDeactivated } = useEthersHooks()
const { address, /*balance,*/ isActivated } = useEthers()

// import useWallet from '@/hooks/useWallte';

import Countdown from '../../components/CountdownTimer.vue'
import { createAlchemyWeb3 } from "@alch/alchemy-web3";


import { http } from 'viem'
import { mainnet } from 'viem/chains'
import { createEnsPublicClient } from '@ensdomains/ensjs'
import ClipboardJS from 'clipboard';

// Create the client
const client = createEnsPublicClient({
    chain: mainnet,
    transport: http(),
})

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/06TjEVJYYTgNryGP7Awp9F9HqnccVp0z"; //"https://eth-goerli.g.alchemy.com/v2/4o6bzNcjg1HE4Z1mTUSNiktqTgZYgf3d" //
const web3alchemy = createAlchemyWeb3(alchemyKey);
// console.log(web3alchemy)
let connectBtnValue = reactive({ value: "Connect" });
let fullAddress = reactive({ value: '' });
let hasConnected = reactive({ value: false });
const hasRaffleId = new URLSearchParams(window.location.search).get("raffleId") !== null;
const raffleId = hasRaffleId ? new URLSearchParams(window.location.search).get("raffleId") : null
let loadedRaffle = reactive({ value: !hasRaffleId });
const currentTime = new Date().getTime();
let raffleDetails = reactive({ value: null });

const postMessage = ref("");



let ownedNFTs: any = reactive({ list: [] });
/// const raffleEntries = ref(0);

// const {
//   onConnect,
//   connected,
//   web3,
//   resetApp,
//   walletObj
// } = useWallet();
const { open } = useBoard()

// const handleConnectClick = async () => {
//   console.log("start handle connect connected: ", hasConnected)
//   if ((hasConnected as any)._object.userAddress) {
//     // resetApp(connectBtnValue)
//     return
//   }

//   await onConnect();
//   if (connected) {
//     console.log("setting window.ethereum: ", web3)
//     window.ethereum = web3
//   } else {
//     console.log('not connected')
//   }

//   connectBtnValue.value = shortenAddress(address.value);
//   fullAddress.value = address.value;
//   hasConnected.value = true;
// }

const enterRaffle = async () => {
    if (!address.value) {
        open()
        // postMessage.value = "Connect wallet to submit your raffle.";
        return;
    }
    postMessage.value = "Sign a message from your wallet to enter the raffle...";
    const nfts = await web3alchemy.alchemy.getNfts({ owner: address.value, contractAddresses: [raffleDetails.value.contract] })

    console.log("enterRaffle nfts: ", nfts)
    let tokenIds: number[] = []

    nfts.ownedNfts.forEach(nft => {
        tokenIds.push(parseInt(nft.id.tokenId))
    })

    if (tokenIds.length < 1) {
        postMessage.value = "No NFTs held in this wallet needed to enter the raffle.";
        return;
    }
    // alert(tokenIds)

    //   const wallet = address.value
    let messageSignature;
    try {

        messageSignature = await wallet.provider.request({
            method: "personal_sign",
            params: ["verify wallet: " + address.value + "\r\nclaiming token(s): " + tokenIds, address.value]
        });

        postMessage.value = "Submitting entry...";
    } catch (error: any) {
        console.log("submitting entry catch...", error)
        error = JSON.parse(error.message)
        // if (error.code == 4001) {
        //   alert(error.message)
        // }
        postMessage.value = error.message;
        return
    }

    fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/submit-raffle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            wallet: address.value,
            messageSignature,
            raffleId,
            tokenIds: tokenIds.toString()
        }),
    }).then((claimed) => {
        console.log("submitRaffle.then(claimed): ", claimed)
        // setPostLoadingScreen.value = false
        // setLoadingScreen.value = false
        if (claimed?.status === 200) {
            // console.log(claimed, props.list)
            // emit("collectionRefresh", true);
            // props.list[index].claimed = true;
            // cancelClaimModal();
            // finishedClaimProcess.value = 'success';
        } else {
            // cancelClaimModal();
            // finishedClaimProcess.value = 'error';
        }

        claimed?.json().then(data => {
            postMessage.value = data.message
        })
    }, () => {
        // setPostLoadingScreen.value = false
        // setLoadingScreen.value = false
        // cancelClaimModal();
        // finishedClaimProcess.value = 'error';
    });
}

const displayAddress = async (address: any) => {
    if (!address) {
        return address
    }
    // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
    const ensName = await client.getName({ address })
    if (ensName) {
        return ensName.name + " (" + shortenAddress(address) + ")"
    }

    return shortenAddress(address);
}

// const { raffleId, hasRaffleId } = useRouteParams()
// const loadedRaffle = ref(false)
// const raffleDetails = ref(null)
// const postMessage = ref("")
const finishedClaimProcess = ref("")
const setPostLoadingScreen = ref(false)
const setLoadingScreen = ref(false)
const cancelClaimModal = ref(false)
const tokenIds = ref("")
const postLoadingScreen = ref(false)
const loadingScreen = ref(false)
const claimModal = ref(false)
// const { wallet } = useWallet()

const submitRaffle = async (tokenIds: any) => {

    if (!tokenIds) {
        postMessage.value = "Please select a token to claim."
        return
    }
    if (!wallet.value) {
        postMessage.value = "Please connect your wallet to claim."
        return
    }
    setLoadingScreen.value = true
    setPostLoadingScreen.value = true
    claimModal.value = true
    postLoadingScreen.value = true
    loadingScreen.value = true
    console.log(tokenIds)
    const wallet = address.value
    let messageSignature;
    try {

        messageSignature = await wallet.provider.request({
            method: "personal_sign",
            params: ["verify wallet: " + address.value + "\r\nclaiming token(s): " + tokenIds, address.value]
        });

        postMessage.value = "Submitting entry...";
    } catch (error: any) {
        console.log("submitting entry catch...", error)
        error = JSON.parse(error.message)
        // if (error.code == 4001) {
        //   alert(error.message)
        // }
        postMessage.value = error.message;
        return
    }

    fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/submit-raffle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            wallet: address.value,
            messageSignature,
            raffleId,
            tokenIds: tokenIds.toString()
        }),
    }).then((claimed) => {
        console.log("submitRaffle.then(claimed): ", claimed)
        // setPostLoadingScreen.value = false
        setLoadingScreen.value
    })
}




onActivated(() => {
    connectBtnValue.value = shortenAddress(address.value);
    fullAddress.value = address.value;
    console.log("onActivated..")
    // displayFAMNFTs(fullAddress.value, nftContractAddressFAM, nftContractAddressArtpocalypse)
    //     .then((list: any) => {
    //         ownedNFTs.list = [...list];
    //         hasConnected.value = true;
    //     });
})

onChanged(() => {
    connectBtnValue.value = shortenAddress(address.value);
    fullAddress.value = address.value;
    // displayFAMNFTs(fullAddress.value, nftContractAddressFAM, nftContractAddressArtpocalypse)
    //   .then((list: any) => {
    //     ownedNFTs.list = [...list];
    //     hasConnected.value = true;
    //   });
})

onDeactivated(() => {
    ownedNFTs.list = [];
    hasConnected.value = false;
    fullAddress.value = "";
})

const onCollectionRefresh = async () => {
    // need to update this displayNFTs for OpenSea projects
    hasConnected.value = false;
    // await displayFAMNFTs(fullAddress.value, nftContractAddressFAM, nftContractAddressArtpocalypse)
    //     .then((list: any) => {
    //         ownedNFTs.list = [...list];
    //         hasConnected.value = true;
    //     });
}


onMounted(async () => {
    if (!hasRaffleId) {
        loadedRaffle.value = true;
        return;
    }

    var response: any = await fetch("https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/get-raffle?raffleId=" + raffleId);
    response = await response.json();

    raffleDetails.value = response.results;
    // console.log(raffleDetails)
    var creatorAddress = await displayAddress(raffleDetails.value.creator)
    // console.log("creatorAddress:", creatorAddress)
    // console.log(raffleDetails.value.creator)
    raffleDetails.value.creator = creatorAddress;
    // console.log(raffleDetails.value.creator)



    // const ABI = [{"inputs":[{"internalType":"uint256","name":"","type":"uint256"}],"name":"famClaimed","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"view","type":"function"}]
    var nameAbi: any = [{
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }]


    const nftContract = new web3alchemy.eth.Contract(nameAbi, raffleDetails.value.contract)
    const contractName = await nftContract.methods.name().call()
    raffleDetails.value.contractName = contractName
    setTimeout(() => { loadedRaffle.value = true; }, 750)


})





const copyToClipboard = () => {
    const fullWinnerAddress = raffleDetails.value.winner;

    // Create a temporary element to copy text to the clipboard
    const tempInput = document.createElement('input');
    tempInput.value = fullWinnerAddress;
    document.body.appendChild(tempInput);

    // Select the text and copy it to the clipboard
    tempInput.select();
    document.execCommand('copy');

    // Remove the temporary element
    document.body.removeChild(tempInput);

    // You can add a notification or any other feedback here
    alert(`Address ${fullWinnerAddress} copied to clipboard!`);
};

// Initialize ClipboardJS
new ClipboardJS('.copyButton');


const notifications = [
    {
        title: 'Fill in Raffle Information',
        description: '1 hour ago',
    },
    {
        title: 'Submit Raffle',
        description: '1 hour ago',
    },
    {
        title: 'Copy Raffle ID',
        description: '2 hours ago',
    },
]

const explores = [
    {
        title: 'Enter Raffle Details',
        description: '1 hour ago',
    },
    {
        title: 'Share Link to Custom Raffle Page',
        description: '1 hour ago',
    },

]

const registers = [
    {
        title: 'Register your wallet',
        description: '1 hour ago',
    },
    {
        title: 'Receive a credit to create a raffle',
        description: '1 hour ago',
    },
    {
        title: 'Reward your community',
        description: '2 hours ago',
    },
]


const navigateToRaffleRegister = () => {
    window.location.href = '/register-raffle-user';
};

const navigateToRaffleCreate = () => {
    window.location.href = '/create-raffle';
};

const navigateToRaffleCreate1155 = () => {
    window.location.href = '/create-raffle-1155';
};

const navigateToRaffleSearch = () => {
    window.location.href = '/raffle';
};


const navigateTo1155RaffleSearch = () => {
    window.location.href = '/raffle-1155';
};

</script>



<template>
    <div class="flex flex-col mx-auto w-full max-w-[1200px] text-center">
        <div class="Instructions py-10 w-[80%] flex flex-col justify-center items-center align-middle mx-auto">
            <h2 class="sm:text-3xl text-2xl mb-3 text-left">Here's how it works</h2>
            <p class="my-10 text-center">Create raffles for Web3 communities with the NFT Redeem Raffle System. Simple to configure, share, and enter.</p>


            <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          

                <div class="mb-4 text-left p-8 border bg-black/80 backdrop-blur-xl flex flex-col justify-between border-white/20 rounded-xl">
                    
                    <a href="#enter">
                        <h2 class="text-2xl text-green-400">Create a Raffle</h2>
                    </a>
                    
                    <p class="text-sm p-2">
                        Register with an Ethereum wallet to create a raffle with our Free Edition. Fill out the details and share your raffle
                    </p>
         

                    <!-- <div class="mt-4">
                        <a href="#enter">
                        <p class="text-sm py-2 px-4 border hover:bg-green-500 hover:text-black transition-all  rounded-md text-white">Take Me There</p>
                    </a>
                    </div> -->
                 
                    
                </div>

                <div class="mb-4 text-left p-8 border bg-black/80 backdrop-blur-xl flex flex-col justify-between border-white/20 rounded-xl">
                    <h2 class="text-2xl text-green-400">Enter a Raffle</h2>
               

                    <p class="text-sm p-2">
                        
                    Visit the raffle page, click “Enter,” and then confirm a read-only transaction from a wallet that holds eligible assets.
                    </p>


                    <!-- <div class="mt-4">
                        <a href="#enter">
                        <p class="text-sm py-2 px-4 border hover:bg-green-500 hover:text-black transition-all  rounded-md text-white">Take Me There</p>
                    </a>
                    </div> -->
                 
                </div>

                <div class="mb-4 text-left p-8 border bg-black/80 backdrop-blur-xl flex flex-col justify-between border-white/20 rounded-xl">
                    <h2 class="text-2xl text-green-400">Winner Results</h2>
                    <p class="text-sm p-2">
                        The winning wallet is randomly chosen and displayed on the raffle page after the configured time to
                        enter has closed.
                    </p>

                    
                    <!-- <div class="mt-4">
                        <a href="#enter">
                        <p class="text-sm py-2 px-4 border hover:bg-green-500 hover:text-black transition-all  rounded-md text-white">Take Me There</p>
                    </a>
                    </div> -->
               
                </div>
            </div>


            <!-- <p class="text-left"> It's that simple! Create raffles, share them with your community, and reward your NFT holders with our easy-to-use tool.</p> -->

        </div>

        <div>
            <div class="wrapper w-full max-w-[1000px] px-8 grid grid-cols-1 lg:grid-cols-2 gap-5 mx-auto">
           
                    <!-- First -->
                    <Card
                        :class="cn(' bg-pink-50 text-pink-800 border-pink-200 rounded-lg w-full', $attrs.class ?? '')">
                        <CardHeader>
                            <span class="text-md mb-4 ml-2">Step 1</span>
                            <CardTitle class="opacity-100 text-[#ff4976]">Register</CardTitle>
                            <CardDescription class="opacity-60">Create an account to get started
                            </CardDescription>
                        </CardHeader>
                        <CardContent class="grid gap-4">

                            <div class="opacity-90">
                                <div v-for="(register, index) in registers" :key="index"
                                    class="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0">
                                    <span class="flex h-2 w-2 translate-y-1 rounded-full bg-[#ff4976]" />
                                    <div class="space-y-1">
                                        <p class="text-sm font-medium leading-none">
                                            {{ register.title }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <CardFooter>
                            <div class="mt-6"></div>
                            <Button
                                class="w-full bg-pink-200 text-pink-800 hover:scale-95 hover:transition-all hover:opacity-80"
                                variant="default" @click="navigateToRaffleRegister">
                                <p>Create Account</p>
                            </Button>
                        </CardFooter>
                    </Card> 

                    
                    <Card
                        id="step2"
                        :class="cn(' bg-blue-50 text-blue-800 border-blue-200 rounded-lg w-full', $attrs.class ?? '')">
                        <CardHeader>
                            <span class="text-md mb-4 ml-2">Step 2</span>
                            <CardTitle class="opacity-100 text-violet-400">Create Raffle </CardTitle>
                            <CardDescription class="opacity-60">Choose the Token Type and Contract Address
                            </CardDescription>
                        </CardHeader>
                        <CardContent class="p-0">
                            <div class="container w-full flex flex-col">
                                <div class="opacity-90">
                                    <div v-for="(explores, index) in explores" :key="index"
                                        class="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 px-8  last:mb-0 last:pb-0">
                                        <span class="flex h-2 w-2 translate-y-1 rounded-full bg-[#A78BFA]" />
                                        <div class="space-y-1">
                                            <p class="text-sm font-medium leading-none">
                                                {{ explores.title }}
                                            </p>

                                        </div>
                                    </div>
                                </div>

                         

                                <!-- <h4 class="py-5">Select Protocol</h4> -->
                                <Tabs default-value="account" class=" mt-5">
                                    <TabsList class="mb-[5px]">
                                        <TabsTrigger value="account">
                                            ERC-721
                                        </TabsTrigger>
                                        <TabsTrigger value="password">
                                            ERC-1155
                                        </TabsTrigger>
                                    </TabsList>
                                    <TabsContent value="account">

                                        <Card :class="cn(' border-0 rounded-lg', $attrs.class ?? '')">

                                            <CardContent class="grid gap-4">

                                            </CardContent>
                                            <CardFooter>
                                                <Button
                                                    class="w-full bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:scale-95 hover:transition-all hover:opacity-80"
                                                    variant="default" @click="navigateToRaffleCreate">
                                                    <p>Configure ERC-721 Raffle</p>
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </TabsContent>
                                    <TabsContent value="password">
                                        <!-- Second -->
                                        <Card :class="cn(' border-0 rounded-lg', $attrs.class ?? '')">

                                            <CardContent class="grid gap-4">


                                            </CardContent>
                                            <CardFooter>


                                                <Button
                                                    class="w-full bg-gradient-to-r from-violet-600 to-indigo-600  hover:scale-95 hover:transition-all hover:opacity-80 text-white"
                                                    variant="default" @click="navigateToRaffleCreate1155">
                                                    <p>Configure ERC-1155 Raffle</p>
                                                </Button>
                                                <!-- <div style="padding-top: 5px;"></div> -->
                                                <!-- <Button
                    class="w-full  text-white bg-gradient-to-r from-violet-600 to-indigo-600  hover:scale-95 hover:transition-all hover:opacity-80"
                    @click="navigateTo1155RaffleSearch">
                    <p>Search ERC1155 Raffle ID</p>
                </Button> -->
                                            </CardFooter>
                                        </Card>
                                    </TabsContent>
                                </Tabs>
                            
                            </div>
                        </CardContent>



                    </Card>
                
            </div>
        </div>


        <div class="">
            <div class="">
                <!-- <Card
                    :class="cn('flex flex-col border-0 rounded-lg justify-center items-center align-middle text-center my-0 mx-auto md:w-full')">
                </Card> -->


                <Card
                    :class="cn('flex flex-row gap-4 py-4 px-4 border-0 flex-wrap w-full justify-center items-center mx-auto')">

               






                </Card>
                <CardFooter class="flex flex-col py-8 justify-center items-center align-middle w-full">
                    <!-- <h3 class="text-3xl"> 🔔 Reminder</h3> -->
                    <!-- <Separator /> -->
                    <!-- <p class="text-sm">Holders of eligible projects will be able to enter an active raffle. Each NFT
                            held will
                            count as 1 entry.
                        </p> -->
                    <!-- <p class="text-sm"> "Whale limit" is capped at 100 entries per wallet by default and can be
                            adjusted
                            upon
                            request.</p> -->
                    <!-- <br /> -->
                    <p class="text-sm">For more information, contact us at <a class="animatedText"
                            href="mailto: info@metavate.io">Info@Metavate.io</a></p>
                </CardFooter>

                <div class="serach my-20">
                    <div class="w-full flex flex-col justify-center items-center text-center align-middle">
                        <div class="my-4 w-88">
                            <h3 class="text-2xl">Search for an existing raffle</h3>
                        </div>
                        <Card>
                            <Card>
                                <Label class="text-xl text-center"> Enter ERC-721 Raffle ID</Label>
                                <form class="flex flex-col " action="/raffle/">
                                    <Input class="mt-4" type="number" name="raffleId" />
                                    <button class="RaffleBtn mt-4" type="submit" value="Search"> Search </button>
                                </form>
                            </Card>
                        </Card>
                        <a href="/raffle-1155" class="mt-4 text-sm text-slate-300" type="submit" value="Search"> Switch
                            to ERC-1155 Raffle
                        </a>
                    </div>
                </div>

                <div class="ActiveRaffles w-full flex flex-col justify-center items-center mb-8 text-left md:px-20">
                    <h2 class="mb-8">Sample Raffle</h2>
                    <div class="flex ActiveWrapper justify-center items-center">
                        <Card class="border-[#ffffff50] rounded-xl">
                            <CardHeader>
                                <img src="https://raw.seadn.io/files/1019acc236598f14a45977b19e4f91b7.png"
                                    class="w-[300px] h-[300px] rounded-xl" />
                                <CardTitle class="text-2xl">Sample Raffle</CardTitle>
                                <CardDescription><strong class="text-violet-600">Reward:</strong> A sweet prize!
                                </CardDescription>
                            </CardHeader>
                            <CardFooter>
                                <a href="https://nftredeem.io/raffle/?raffleId=298"
                                    class="bg-gradient-to-r from-violet-600 to-indigo-600 inline-block px-4 py-2 rounded-md text-white hover:bg-white hover:text-black">
                                    View Raffle
                                </a>
                            </CardFooter>
                        </Card>
                    </div>
                </div>




            </div>
        </div>
    </div>
</template>

<style></style>