<script setup lang="ts">

/* eslint-disable */
import { reactive, onMounted, ref } from 'vue';
import { useBoard, useEthers, useWallet, useEthersHooks, shortenAddress } from 'vue-dapp'
import { Button } from '../components/ui/button'

// UI
import { cn } from '../lib/utils'


const date = ref<Date>()

const {
  disconnect,
  wallet
} = useWallet();
const { onActivated, onChanged, onDeactivated } = useEthersHooks()
const { address, /*balance,*/ isActivated } = useEthers()


import Countdown from '../components/CountdownTimer.vue'
import { createAlchemyWeb3 } from "@alch/alchemy-web3";


import { http } from 'viem'
import { mainnet } from 'viem/chains'
import { createEnsPublicClient } from '@ensdomains/ensjs'
import ClipboardJS from 'clipboard';

// Create the client
const client = createEnsPublicClient({
  chain: mainnet,
  transport: http(),
})

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/06TjEVJYYTgNryGP7Awp9F9HqnccVp0z"; //"https://eth-goerli.g.alchemy.com/v2/4o6bzNcjg1HE4Z1mTUSNiktqTgZYgf3d" //
const web3alchemy = createAlchemyWeb3(alchemyKey);
let hasConnected = reactive({ value: false });
const hasRaffleId = new URLSearchParams(window.location.search).get("raffleId") !== null;
const raffleId = hasRaffleId ? new URLSearchParams(window.location.search).get("raffleId") : null
let loadedRaffle = reactive({ value: !hasRaffleId });
const currentTime = new Date().getTime();
let raffleDetails = reactive({ value: null });

const postMessage = ref("");



const description = ref("");
const reward = ref("");
const rewardimage = ref("");
const starttime = ref("");
const endtime = ref("");
const contract = ref("");
const startDate = ref<Date>()
const endDate = ref<Date>()
const blocknumber = ref();
console.log(starttime);
// const postMessage = ref("");


let startClaimProcess: any = ref(false);



let ownedNFTs: any = reactive({ list: [] });
const { open } = useBoard()


const displayAddress = async (address: any) => {
  if (!address) {
    return address
  }
  // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
  const ensName = await client.getName({ address })
  if (ensName) {
    return ensName.name + " (" + shortenAddress(address) + ")"
  }

  return shortenAddress(address);
}

const cancelClaimModal = ref(false)
const tokenIds = ref("")

const postLoadingScreen = ref(false)
const loadingScreen = ref(false)
const claimModal = ref(false)
const useCurrentBlock = ref(true)
const useAllTokenIds = ref(true)

const submitRaffle = async (address: any, { description, reward, rewardimage, starttime, endtime, contract }: any) => {
  startClaimProcess.value = false;
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  // const wallet = address
  let messageSignature;
  console.log("submitRaffle...")
  console.log("useCurrentBlock: ", useCurrentBlock.value)
  var blockTimestamp
  if (useCurrentBlock.value == true) {
    blockTimestamp = await web3alchemy.eth.getBlockNumber()
  } else {
    blockTimestamp = blocknumber.value
  }
  console.log("blockTimestamp: ", blockTimestamp)
  try {
    messageSignature = await wallet.provider.request({ method: "personal_sign", params: ["verify wallet: " + address + "\r\ncreating raffle\r\nreward: " + reward + "\r\ndescription: " + description, address] })
    console.log("messageSignature: ", messageSignature)
  } catch (error: any) {
    // if(error.code == 4001){
    // }
    console.log(error)
    postMessage.value = error.message;
    return
  }
  console.log("submitting raffle fetch")
  var fetchResults = await fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/create-1155-raffle', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      wallet: address,
      messageSignature,
      description,
      reward,
      rewardimage,
      starttime,
      endtime,
      contract,
      tokenIds: tokenIds.value,
      blocknumber: blockTimestamp
    }),
  });
  console.log("fetchResults: ", fetchResults)
  return fetchResults
};


const createRaffle = async () => {

  if (!wallet.provider) {
    open()
    postMessage.value = "Please connect your wallet."
    return
  }

  postMessage.value = "Submitting raffle...";
  var fetchResults = await submitRaffle(address.value, {
    description: description.value,
    reward: reward.value,
    rewardimage: rewardimage.value ? rewardimage.value : "",
    starttime: new Date(starttime.value).getTime(),
    endtime: new Date(endtime.value).getTime(),
    contract: contract.value
  })
  var responseBody = await fetchResults?.json();
  console.log("submitRaffle responseBody = ", responseBody)
  postMessage.value = "Created new raffle! Check it out at " + location.origin + "/raffle-1155/?raffleId=" + responseBody.message
}

onActivated(() => {
  // console.log("onActivated..")
})

onChanged(() => {
  if (wallet.provider.accounts) {
    address.value = wallet.provider.accounts[0];
  }
})

onDeactivated(() => {
  hasConnected.value = false;
})

const navigateToRaffleHome = () => {
  window.location.href = '/raffle-home';
};

function toggleBlocknumber() {
  useCurrentBlock.value = !useCurrentBlock.value
  console.log("useCurrentBlock: ", useCurrentBlock.value)
}


function updateBlocknumber(e: any) {
  blocknumber.value = e.target.value
  console.log("blocknumber.value: ", blocknumber.value)
}


function toggleTokenIds() {
  useAllTokenIds.value = !useAllTokenIds.value
  console.log("useAllTokenIds: ", useAllTokenIds.value)
}


</script>


<template>
     <div class="
     bg-[#0e0e0e] 
     flex 
     flex-1 
     w-full 
     md:flex-row 
     
     px-4 
     md:px-8 
     py-3 
     items-center 
     align-middle 
     md:justify-between 
     justify-center
     text-center
     border-b
     border-[#ffffff20]">

<!-- Logo -->
<div class="md:mb-0 flex flex-1 align-middle text-center justify-start">
    <a href="/">
        <h1 class="text-[20px] mb-2 md:text-3xl text-[#ff4976]"><span class="text-violet-400">NFT Redeem </span>Raffle System</h1>
    </a>
</div>

<!-- Wallet Button -->
<div class="md:mb-0 ">
    <!-- Disconnect Wallet -->
    <div v-if="isActivated" class="text-sm flex gap-4 justify-center items-center text-center align-middle md:text-base">
        <!-- Wallet Address -->
        <div class="hidden md:flex">
            {{ shortenAddress(address) }}
        </div>
        <!-- Disconnect Button -->
        <div>
            <Button @click="disconnect" class="" variant="outline">Disconnect</Button>
        </div>
    </div>

    <!-- Connect Wallet -->
    <Button v-else @click="open()" class="text-sm md:text-base" variant="outline" :disabled="wallet.status === 'connecting'">
        {{
        wallet.status === 'connecting'
        ? 'Connecting...'
        : wallet.status === 'loading'
        ? 'Loading...'
        : 'Connect'
        }}
    </Button>
</div>
</div>


</template>
     
<style></style>
  