<script setup lang="ts">


/* eslint-disable */
import { reactive, onMounted, ref } from 'vue';

import { useBoard, useEthers, useWallet, useEthersHooks, shortenAddress } from 'vue-dapp'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as React from 'react';

// UI
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-vue-next'

import { BellRing, Search, Undo2 } from 'lucide-vue-next'
import { Separator } from '../../components/ui/separator'
import { Button } from '../../components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '../../components/ui/card'
import { Switch } from '../../components/ui/switch'
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Calendar } from '../../components/ui/calendar'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../../components/ui/popover'
import { cn } from '../../lib/utils'
import { Alert, AlertDescription } from '../../components/ui/alert'

const date = ref<Date>()

const {
    disconnect,
    wallet
} = useWallet();
const { onActivated, onChanged, onDeactivated } = useEthersHooks()
const { address, /*balance,*/ isActivated } = useEthers()


import Countdown from '../components/CountdownTimer.vue'
import { createAlchemyWeb3 } from "@alch/alchemy-web3";


import { http } from 'viem'
import { mainnet } from 'viem/chains'
import { createEnsPublicClient } from '@ensdomains/ensjs'
import ClipboardJS from 'clipboard';

// Create the client
const client = createEnsPublicClient({
    chain: mainnet,
    transport: http(),
})

const alchemyKey = "https://eth-mainnet.g.alchemy.com/v2/06TjEVJYYTgNryGP7Awp9F9HqnccVp0z"; //"https://eth-goerli.g.alchemy.com/v2/4o6bzNcjg1HE4Z1mTUSNiktqTgZYgf3d" //
const web3alchemy = createAlchemyWeb3(alchemyKey);
let hasConnected = reactive({ value: false });
const hasRaffleId = new URLSearchParams(window.location.search).get("raffleId") !== null;
const raffleId = hasRaffleId ? new URLSearchParams(window.location.search).get("raffleId") : null
let loadedRaffle = reactive({ value: !hasRaffleId });
const currentTime = new Date().getTime();
let raffleDetails = reactive({ value: null });

const postMessage = ref("");



const description = ref("");
const reward = ref("");
const rewardimage = ref("");
const starttime = ref("");
const endtime = ref("");
const contract = ref("");
const startDate = ref<Date>()
const endDate = ref<Date>()
console.log(starttime);
// const postMessage = ref("");


let startClaimProcess: any = ref(false);



let ownedNFTs: any = reactive({ list: [] });
const { open } = useBoard()


const displayAddress = async (address: any) => {
    if (!address) {
        return address
    }
    // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
    const ensName = await client.getName({ address })
    if (ensName) {
        return ensName.name + " (" + shortenAddress(address) + ")"
    }

    return shortenAddress(address);
}

const cancelClaimModal = ref(false)
const tokenIds = ref("")
const postLoadingScreen = ref(false)
const loadingScreen = ref(false)
const claimModal = ref(false)

const submitRaffle = async (address: any, { description, reward, rewardimage, starttime, endtime, contract }: any) => {
    startClaimProcess.value = false;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    // const wallet = address
    let messageSignature;
    console.log("submitRaffle - wallet: ", wallet)
    console.log("address: ", address)
    try {
        messageSignature = await wallet.provider.request({ method: "personal_sign", params: ["verify wallet: " + address + "\r\ncreating raffle\r\nreward: " + reward + "\r\ndescription: " + description, address] })
        console.log("messageSignature: ", messageSignature)
    } catch (error: any) {
        // if(error.code == 4001){
        // }
        console.log(error)
        postMessage.value = error.message;
        return
    }
    console.log("submitting raffle fetch")
    var fetchResults = await fetch('https://9kruhmb8w1.execute-api.us-east-1.amazonaws.com/create-raffle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            wallet: address,
            messageSignature,
            description,
            reward,
            rewardimage,
            starttime,
            endtime,
            contract
        }),
    });
    console.log("fetchResults: ", fetchResults)
    return fetchResults
};


const createRaffle = async () => {

    if (!wallet.provider) {
        open()
        postMessage.value = "Please connect your wallet."
        return
    }

    postMessage.value = "Submitting raffle...";
    var fetchResults = await submitRaffle(address.value, {
        description: description.value,
        reward: reward.value,
        rewardimage: rewardimage.value ? rewardimage.value : "",
        starttime: new Date(starttime.value).getTime(),
        endtime: new Date(endtime.value).getTime(),
        contract: contract.value
    })
    var responseBody = await fetchResults?.json();
    console.log("submitRaffle responseBody = ", responseBody)
    postMessage.value = location.origin + "/raffle/?raffleId=" + responseBody.message;
    location.href = location.origin + "/raffle/?raffleId=" + responseBody.message;
}



onActivated(() => {
    // console.log("onActivated..")
})

onChanged(() => {
    if (wallet.provider.accounts) {
        address.value = wallet.provider.accounts[0];
    }
})

onDeactivated(() => {
    hasConnected.value = false;
})

const navigateToRaffleHome = () => {
    window.location.href = '/raffle-home';
};





function updatePreview(url) {
    const imagePreviewImg = document.getElementById('imagePreviewImg');
    if (imagePreviewImg) {
        imagePreviewImg.src = url;
    }
}

async function fetchImage() {
    try {
        const response = await fetch(rewardimage.value);
        if (!response.ok) {
            throw new Error('Failed to fetch image');
        }
        const imageUrl = URL.createObjectURL(await response.blob());
        updatePreview(imageUrl);
    } catch (error) {
        console.error('Error fetching image:', error.message);
    }
}

</script>

<template>
    <div class=" flex justify-center w-full">
        <Card :class="cn('flex flex-col border-0 w-[1200px] mx-auto')">
            <form name="raffle-admin-form" @submit.prevent="createRaffle()">
                <CardHeader>
                    <div class="flex w-full justify-between align-top items-start">
                        <div class="flex flex-col">
                            <CardTitle :class="cn('text-xl md:text-3xl')">Create Raffle</CardTitle>
                            <CardDescription class="text-sm md:text-base opacity-50">Choose the Protocol and Contract Address
                            </CardDescription>
                        </div>

                        <div class="flex text-center">
                            <Button variant="outline" class=" hover:bg-white hover:text-black" @click="navigateToRaffleHome"
                                href="/raffle-home">
                                <Undo2 :class="cn('mr-2 text-xs md:text-sm')" /> Back
                            </Button>
                        </div>
                    </div>
                </CardHeader>

                <CardContent class="flex flex-col md:flex-row">
                    <div class="wrapper w-full flex flex-wrap justify-between items-center align-middle">
                        <!-- DETAIL SECTION -->
                        <div class="section md:w-[40%] px-8 w-full">
                            <div class="flex flex-col items-start space-y-2">
                                <Label for="description">Raffle Title <span class="text-red-500">*</span></Label>
                                <Input type="text" id="description" placeholder="ex: Custom Art Giveaway" v-model="description" required
                                    class="w-full md:w-auto" />
                            </div>

                            <div class="flex flex-col items-start space-y-2 mt-4  w-full">
                                <Label for="reward">Raffle Reward <span class="text-red-500">*</span></Label>
                                <Input type="text" id="reward" v-model="reward" placeholder="ex: Hand drawn sneakers" required
                                    class="w-full md:w-auto" />
                            </div>
                            <div class="flex flex-col items-start space-y-2 mt-4  w-full">
                                <Label for="starttime">Start Date <span class="text-red-500">*</span></Label>
                                <Input type="datetime-local" v-model="starttime" id="starttime" required
                                    class="w-full md:w-auto" />
                            </div>

                            <div class="flex flex-col items-start space-y-2 mt-4  w-full">
                                <Label for="endtime">End Date <span class="text-red-500">*</span></Label>
                                <Input type="datetime-local" v-model="endtime" id="endtime" required
                                    class="w-full md:w-auto" />
                            </div>

                            <div class="flex flex-col items-start space-y-2 mt-4" id="raffle-contracts">
                                <Label for="email">Eligible Contract <span class="text-red-500">*</span></Label>
                                <Input type="text" pattern="0x.{40}"
                                    oninvalid="this.setCustomValidity('Please enter a valid contract address')"
                                    onchange="try{setCustomValidity('')}catch(e){}" oninput="setCustomValidity(' ')"
                                    v-model="contract" required placeholder="NFT contract address (0x123...ABC)" />
                            </div>

                        </div>

                        <!-- IMAGE SECTION -->
                        <div class="section md:w-[60%] flex flex-col p-8">
                            <div class="imagePreview flex">
                                <div
                                    class="h-80 w-full flex flex-1 rounded-lg  object-contain bg-[#ffffff10] border border-[#ffffff20] ">
                                    <div class="h-full w-full flex bg-no-repeat object-contain justify-center items-center align-middle"
                                        id="imagePreview">
                                        <img :src="rewardimage" alt="Paste a valid image URL below"
                                            class="h-full w-full object-contain flex justify-center items-center"
                                            id="imagePreviewImg">
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col items-start space-y-2 mt-4">
                                <label for="email">Raffle Image URL <span class="text-red-500">*</span> <span class="text-[10px]">Image will display if valid</span></label>
                                <div class="flex w-full gap-4">
                                    <Input 
                                    type="text" 
                                    id="rewardimage" 
                                    v-model="rewardimage"
                                    placeholder="https://example.com/your-image.png" 
                                    @input="updatePreview(rewardimage)"
                                    required />
                                    <!-- <Button @click="fetchImage" class="bg-white text-black rounded-md">Fetch Image</button> -->
                                </div>
                            </div>
                        </div>

                    </div>

                </CardContent>

                <CardFooter class="flex gap-4 md:flex-col flex-col-reverse">
                    <!-- <p>By submitting raffle, you agree to give winner the reward</p> -->
                    <Button type="submit" variant="outline" value="Search"
                        :class="cn('RaffleBtn', 'w-full text-xl h-24', 'md:w-[200px] md:h-auto md:text-lg')">Create
                        Raffle</Button>
                    <div v-if="postMessage" class="bg-green-600 rounded-sm justify-center items-center align-middle text-center flex px-8 py-2 text-white" id="postMessage">{{ postMessage }}</div>
                </CardFooter>

            </form>
        </Card>
    </div>
</template>
     
<style></style>
  
